// import moment from 'moment';
import moment from 'moment-timezone';
import * as actions from '../authentication/AuthenticationAction';
import resc from '../../utils/resource.json';

var resource = resc[localStorage.getItem("envDetail")];

const isTokenValid = (expiryTimeUTC) => {

var m = moment.utc(expiryTimeUTC);
// convert using the TZDB identifier for US Central time
m.tz('America/Chicago');
  var time = moment.utc((expiryTimeUTC * 1000) - 60000);
  time.tz('America/Chicago');
  var sessionTime = time.format("YYYY-MM-DD HH:mm:ss");
  const tick = setInterval(()=> {
  const now = moment();
    now.tz('America/Chicago');
    var currentTime = now.format("YYYY-MM-DD HH:mm:ss");
    if(sessionTime <= currentTime) {
        clearInterval(tick);
        localStorage.clear();
        setTimeout(()=>{
            console.log('qwerty');
            actions.listenLogout();
            actions.logoutSuccess();
            const idToken = localStorage.getItem('token');
            window.location.href = resource.logoutUrl.replace('${IDTOKEN}', idToken);
        },1000)
    }
},1000)
  
};

export default isTokenValid