import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "../../authentication/AuthenticationAction";
import { useHistory } from 'react-router-dom';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import strings from "../../../resources/strings.json"
import "../navbar.scss";
import SettingsIcon from "../../../assets/images/settings_icon.svg";
import HelpAndFeedback from "../../../assets/images/help_and_feedback_icon.svg";
import InformationIcon from "../../../assets/images/about_icon.svg";
import MandoProfileIcon from "../../../assets/images/about_icon.svg";
import ShareIcon from "../../../assets/images/share_icon.svg";
import LogoutIcon from "../../../assets/images/exit_icon.svg";
import { Collapse } from 'react-bootstrap';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import Notify from '../../notification/notify';
import { unreadCount } from '../../../constants/constants';
import instance_api from '../../../config/axiosConfig';

function MobileLinks({ setOpenAbout, setShowRelease, action, activeRelease, additionalAccess}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const history = useHistory();
  const [showSubMenu, setShowSubMenu] = useState(null);
  const [showMyTime, setShowMyTime] = useState(null);
  const [unreadNotificationCount, setUnreadNotificationCount] = useState(0);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const showFeedback = () => {
    history.push("/feedback");
    setShowSubMenu(null);
    setShowMyTime(null);
    setAnchorEl(null);
  }

  const showMyTimeDashBoard = () => {
    history.push("/myTimeDashboard");
    setShowSubMenu(null);
    setShowMyTime(null);
    setAnchorEl(null);
  }

  const showNotification = () => {
    history.push("/notification");
    setShowSubMenu(null);
    setShowMyTime(null);
    setAnchorEl(null);
  }

  const showFaqs = () => {
    history.push("/faq");
    setShowSubMenu(null);
    setShowMyTime(null);
    setAnchorEl(null);
  }

  const showGenerateAbsenceReports = () => {
    history.push("/absence-report");
    setShowSubMenu(null);
    setShowMyTime(null);
    setAnchorEl(null);
  }

  const showManageReports = () => {
    history.push("/distro");
    setShowSubMenu(null);
    setShowMyTime(null);
    setAnchorEl(null);
  }

  const showSuperAdminContacts = () => {
    history.push("/admin-contacts");
    setShowSubMenu(null);
    setShowMyTime(null);
    setAnchorEl(null);
  }

  const showSubmitEmployeeRequest = () => {
    history.push("/admin");
    setShowSubMenu(null);
    setShowMyTime(null);
    setAnchorEl(null);
  }

  const showRecentAbsenceReports = () => {
    history.push("/recent");
    setShowSubMenu(null);
    setShowMyTime(null);
    setAnchorEl(null);
  }

  const showStationAccess = () => {
    history.push("/station-access");
    setShowSubMenu(null);
    setShowMyTime(null);
    setAnchorEl(null);
  }

  const showManageDocuments = () => {
    history.push("/manage-documents");
    setShowSubMenu(null);
    setShowMyTime(null);
    setAnchorEl(null);
  }

  const showCreateUpdateRelease = () => {
    history.push("/create-update-Release");
    setShowSubMenu(null);
    setShowMyTime(null);
    setAnchorEl(null);
  }

  const showManageAbsenceTypes = () => {
    history.push("/manage-absence-types");
    setShowSubMenu(null);
    setShowMyTime(null);
    setAnchorEl(null);
  }

  const showMandoDetails = () => {
    history.push("/mando_RequestDetails");
    setShowSubMenu(null);
    setShowMyTime(null);
    setAnchorEl(null);
  }

  const showUnionProfile = () => {
    history.push("/unionProfile");
    setShowSubMenu(null);
    setShowMyTime(null);
    setAnchorEl(null);
  }

    const showAdminFeedback = () => {
    history.push("/adminFeedback");
    setShowSubMenu(null);
    setShowMyTime(null);
    setAnchorEl(null);
  }

  const showMandoRequestDetails = () => {
    history.push("/mando_details");
    setShowSubMenu(null);
    setShowMyTime(null);
    setAnchorEl(null);
  }

  const getUnreadNotificationCount = async () => {
    const response = await instance_api("GET", unreadCount());
    if (response?.status === 200){
      if (response.data != []) {
          setUnreadNotificationCount(response.data);
      }
    }
    else {
      console.log("ERROR: " + response?.response?.status);
    }
  }

  useEffect(() => {
    getUnreadNotificationCount();
  }, []);

  return (
    <>
      <div>
        <IconButton
          id="menu-button"
          aria-controls="mobile-menu"
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={showNotification}
          style={{ "marginRight": "10px" }}
        >
          <Notify width={"30px"} color={"#122C34"} count={unreadNotificationCount} />
        </IconButton>
        <IconButton
          id="menu-button"
          aria-controls="mobile-menu"
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          style={{ "marginRight": "10px", color: "#122C34" }}
        >
          <MenuItem component="a" href="/mando-profile" onClick={() => { handleClose(); }}>
            <AccountCircleOutlinedIcon fontSize='large'/>
            </MenuItem>
        </IconButton>
        <IconButton
          id="menu-button"
          aria-controls="mobile-menu"
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          style={{ "marginRight": "10px" }}
        >
          <MenuIcon fontSize="large" color="primary" />
        </IconButton>
        <Menu
          id="mobile-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'menu-button',
          }}
        >
          {
            (activeRelease != null && activeRelease != "") &&
            <MenuItem className="menu-item" onClick={() => { handleClose(); setShowRelease(true); }}>{strings.newRelease}</MenuItem>
          }
          {
            localStorage.getItem(strings.adminType) != strings.normal &&
              <div>
                <MenuItem className="menu-item" onClick={() => showSubMenu != strings.adminFeatures ? setShowSubMenu(strings.adminFeatures)
                  : setShowSubMenu(null)}>
                  <SupervisorAccountIcon style={{ marginRight: 20 }} />
                  {strings.adminFeatures}
                </MenuItem>
                <Collapse
                  in={showSubMenu == strings.adminFeatures}>
                  <div>
                    {
                      [strings.mytime_admin, strings.mm_admin, strings.super, strings.mm_read_admin, strings.mt_read_admin].includes(localStorage.getItem(strings.adminType)) &&
                      <div>
                        <MenuItem className="subMenu" onClick={() => showMyTime != strings.myTime ? setShowMyTime(strings.myTime)
                          : setShowMyTime(null)}>
                          <SupervisorAccountIcon style={{ marginRight: 20 }} />
                          {strings.myTime}
                        </MenuItem>
                        <Collapse
                          in={showMyTime == strings.myTime}>
                          {[strings.mando_admin, strings.mm_admin, strings.super].includes(localStorage.getItem(strings.adminType))
                          ?
                          <ul>
                            <li className="subMenu" onClick={showGenerateAbsenceReports}>{strings.generateAbsenceReports}</li>
                            {
                              localStorage.getItem(strings.adminType) === strings.super &&
                              <li className="subMenu" onClick={showManageReports}>{strings.manageReports}</li>
                            }
                            <li className="subMenu" onClick={showSuperAdminContacts}>{strings.superAdminContacts}</li>
                            <li className="subMenu" onClick={showSubmitEmployeeRequest}>{strings.submitEmployeeRequest}</li>
                            <li className="subMenu" onClick={showRecentAbsenceReports}>{strings.recentAbsenceReports}</li>
                            {
                              additionalAccess &&
                              <li className="subMenu" onClick={showStationAccess}>{strings.stationAccess}</li>
                            }
                            {
                              additionalAccess &&
                              <li className="subMenu" onClick={showManageDocuments}>{strings.manageDocuments}</li>
                            }
                            {
                              additionalAccess &&
                              <li className="subMenu" onClick={showCreateUpdateRelease}>{strings.createUpdateRelease}</li>
                            }
                            {
                              additionalAccess &&
                              <li className="subMenu" onClick={showManageAbsenceTypes}>{strings.manageAbsenceTypes}</li>
                            }
                          </ul>
                          :
                          <ul>
                            <li className="subMenu" onClick={showGenerateAbsenceReports}>{strings.generateAbsenceReports}</li>
                            <li className="subMenu" onClick={showSuperAdminContacts}>{strings.superAdminContacts}</li>
                            <li className="subMenu" onClick={showRecentAbsenceReports}>{strings.recentAbsenceReports}</li>
                          </ul>
                          }
                        </Collapse>
                      </div>
                    }
                    {
                      [strings.mando_admin, strings.mm_admin, strings.super, strings.mm_read_admin, strings.ot_read_admin].includes(localStorage.getItem(strings.adminType)) &&
                      <div>
                        <MenuItem className="subMenu" onClick={() => showMyTime != strings.mando ? setShowMyTime(strings.mando)
                          : setShowMyTime(null)}>
                          <SupervisorAccountIcon style={{ marginRight: 20 }} />
                          {strings.mando}
                        </MenuItem>
                        <Collapse
                          in={showMyTime == strings.mando}>
                            {[strings.mando_admin, strings.mm_admin, strings.super].includes(localStorage.getItem(strings.adminType))
                            ?
                            <ul>
                              <li className="subMenu" onClick={showMandoDetails}>{strings.modDashBoard}</li>
                              <li className="subMenu" onClick={showMandoRequestDetails}>{strings.modDashBoardRecent}</li>
                              <li className="subMenu" onClick={showUnionProfile}>{strings.unionMandoNotif}</li>
                              <li className="subMenu" onClick={showAdminFeedback}>{strings.adminFeedback}</li>
                            </ul>
                            :
                            <ul>
                              <li className="subMenu" onClick={showMandoDetails}>{strings.modDashBoard}</li>
                              <li className="subMenu" onClick={showAdminFeedback}>{strings.adminFeedback}</li>
                            </ul>
                            }
                        </Collapse>
                      </div>
                    }
                  </div>
                 
                </Collapse>
              </div>
          }
          {
            localStorage.getItem(strings.adminType) != strings.normal &&
            <MenuItem className="menu-item" component="a" href="/set_HomePage" onClick={() => { handleClose(); }}>
            <img src={SettingsIcon} style={{ paddingRight: 20 }} />
            {strings.setHomePage}</MenuItem>
          }
          <MenuItem className="menu-item" onClick={() => {showMyTimeDashBoard()}}>
            <img src={InformationIcon} style={{paddingRight: 20}}/>
            {strings.myTimeDashboard}</MenuItem>
          <MenuItem className="menu-item" onClick={() => { handleClose(); setOpenAbout(true); }}>
            <img src={InformationIcon} style={{paddingRight: 20}}/>
            {strings.menuAbout}</MenuItem>
          <div>
            <MenuItem className="menu-item" onClick={() => showSubMenu != strings.helpFeedback ? setShowSubMenu(strings.helpFeedback)
              : setShowSubMenu(null)}>
              <img src={HelpAndFeedback} style={{paddingRight: 20}}/>
              {strings.helpFeedback}
            </MenuItem>
            <Collapse
              in={showSubMenu == strings.helpFeedback}>
              <ul>
                <li className="subMenu" onClick={showFaqs}>{strings.faqs}</li>
                <li className="subMenu" onClick={showFeedback}>{strings.feedback}</li>
              </ul>
            </Collapse>
          </div>
          <MenuItem className="menu-item" component="a" href="/helpDocuments" onClick={() => { handleClose(); }}>
            <img src={SettingsIcon} style={{paddingRight: 20}}/>
            {strings.helpDocument}</MenuItem>
          <MenuItem className="menu-item" component="a" href="/download-mobile" onClick={() => { handleClose(); }}>
            <img src={ShareIcon}  style={{paddingRight: 20}}/>
            {strings.downloadMobile}</MenuItem>
          <MenuItem className="menu-item" component="a" href="/links" onClick={() => { handleClose(); }}>
            <img src={SettingsIcon}  style={{paddingRight: 20}}/>
            {strings.links}</MenuItem>
          <MenuItem className="menu-item" component="a" href="#" onClick={() => { action.logoutSuccess(); }}>
            <img src={LogoutIcon}  style={{paddingRight: 20}}/>
            {strings.logout}</MenuItem>
        </Menu>
      </div></>
  );
}

const mapStateToProps = (state) => ({
  decodedToken: state.AuthenticationReducer.decodedToken,
  accessToken: state.AuthenticationReducer.accessToken,
  exp: state.AuthenticationReducer.exp,
  activeRelease: state.HomePageReducer.activeRelease,
  additionalAccess: state.HomePageReducer.additionalAccess,
});

const mapDispatchToProps = (dispatch) => ({
  action: bindActionCreators({ ...actions }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(MobileLinks);