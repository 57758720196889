import React from "react";
import { Divider } from "@mui/material";
import useMediaQuery from '@mui/material/useMediaQuery';
import MobileLinks from "./navbar-links/navbar-links-mobile";
import LogoImage from "../../assets/images/icons/aa-logo-gray.png"
import strings from "../../resources/strings.json"
import "./navbar.scss";
import About from './about/About';
import ReleaseNotes from "../createUpdateRelease/release-notes";

function NavigationBar() {
  const [openAbout, setOpenAbout] = React.useState(false);
  const [showRelease, setShowRelease] = React.useState(false);
  const isMediaLarge = useMediaQuery('(min-width:600px)');
  const DividerStyleProps = {
    borderTopWidth: '1px',
    borderTopStyle: 'solid',
  }
  return (
    <>
      <nav className="navbar-items nav-bar">
        <div className={isMediaLarge ? "logo-container" : "logo-container logo-alignment"}>
          <a href="/myTimeDashboard">
            <img src={LogoImage} alt="logo" className="logo-image" />
            <h1 className="logo-text">{strings.myTime}</h1>
          </a>
        </div>
        <span className="fill-remaining-space"></span>
        <MobileLinks setOpenAbout={setOpenAbout} setShowRelease={setShowRelease}/>
      </nav>
      <div className='divider'>
        <Divider style={DividerStyleProps}></Divider>
      </div>
      {openAbout ? <About open={openAbout} handleClose={() => setOpenAbout(false)} /> : null}
      {showRelease?  <ReleaseNotes open={showRelease} handelClose={() => 
        setShowRelease(false)
        } /> : null}
    </>
  )
}

export default NavigationBar