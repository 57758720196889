const initialState = {
  departmentsAll: [],
  departmentsNonAdmin: [],
  leaveTypes: [],
  miscTypes:[],
  empStationCode: '',
  activeRelease: '',
  additionalAccess: false,
  additionalProperties:[],
  fmlaPersonalTypes: [],
  fmlaCaregiverTypes: [],
  fmlaCaregiverPayTypes: [],
  fmlaPersonalPayTypes: [],
  bereavementRelationshipTypes: [],
  releaseInfo: {},
  absenceType: {},
  absenceStation: "",
  editAbsenceData: {},
  homePage: undefined
};

export const HomePageReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_EMP_STATION": {
      return {
        ...state,
        empStationCode: action.payload,
      };
    }
    case "SET_ABSENCE_TYPE": {
      return {
        ...state,
        absenceType: action.payload,
      };
    }
    case "SET_ABSENCE_STATION": {
      return {
        ...state,
        absenceStation: action.payload,
      };
    }
    case "EDIT_ABSENCE_TYPE": {
      return {
        ...state,
        editAbsenceData: action.payload,
      };
    }

    case "RE_SET_EMP_STATION": {
      return {
        ...state,
        empStationCode: "",
      };
    }
    case "SET_RELEASE_DATA": {
      return {
        ...state,
        releaseInfo: action.payload,
      };
    }
    //
    case "SET_DATA": {
      return {
        ...state,
        departmentsAll: action.payload?.departments?.map(
          (ele) => ele?.departmentName
        ),
        departmentsNonAdmin: action.payload?.departments
          ?.filter((ele) => ele.admin === false)
          .map((ele) => ele?.departmentName),
        leaveTypes: action.payload?.leaveTypes,
        miscTypes: action.payload?.miscellaneousLeaveTypes,
        additionalProperties: action.payload?.additionalProperties,
        homePage: action.payload?.additionalProperties?.homePage,
        fmlaPersonalTypes: action.payload?.fmlaPersonal,
        activeRelease: action.payload?.activeRelease,
        fmlaCaregiverTypes: action.payload?.fmlaCaregiver,
        fmlaCaregiverPayTypes: action.payload?.fmlaCaregiverPayTypes,
        fmlaPersonalPayTypes: action.payload?.fmlaPersonalPayTypes,
        bereavementRelationshipTypes: action.payload?.bereavementRelationshipTypes,
        additionalAccess: action.payload?.additionalProperties?.additionalAccess,
      }
    }
    case "RESET_DATA": {
      return initialState;
    }
    default: {
      return { ...state };
    }
  }
};

export default HomePageReducer;
