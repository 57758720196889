/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

const CustomDialogModel = ({ headder, footer, ...props }) => (
  <Dialog
    {...props}
    TransitionComponent={Transition}
    keepMounted
    PaperProps={{
      style: {
        ...props.styles,
      },
    }}
    aria-describedby="alert-dialog-slide-description"
  >
    {headder ? (<DialogTitle style={props.headerStyles ? props.headerStyles : {}}>{headder}</DialogTitle>) : null}
    <DialogContent>
      {props.children}
    </DialogContent>
    {footer ? (
      <DialogActions>
        {footer}
      </DialogActions>
    ) : null}
  </Dialog>
);

CustomDialogModel.defaultProps = {
  headder: <></>,
  footer: <></>,
  styles: {},
};

CustomDialogModel.propTypes = {
  children: PropTypes.instanceOf(Object).isRequired,
  headder: PropTypes.instanceOf(Object),
  footer: PropTypes.instanceOf(Object),
  styles: PropTypes.instanceOf(Object),
};

export default CustomDialogModel;
