import { combineReducers } from 'redux';

import AuthenticationReducer from '../../components/authentication/AuthenticationReducer';
import HomePageReducer from '../../components/home-page/home-page-store/HomePageReducer';

const rootReducer = combineReducers({
    AuthenticationReducer,
    HomePageReducer
});

export default rootReducer;