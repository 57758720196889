import {  takeEvery } from 'redux-saga/effects'

import { PERFORM_LOGIN, LOGIN_AUTH, REMOVED_TOKENS_SUCCESS } from '../../components/authentication/AuthenticationConstants';
import { fetchAccessToken, loginAuth, clearAuthResponse  } from './authentication';

export function* watchAuthentication() {
    yield takeEvery(PERFORM_LOGIN, fetchAccessToken);
    yield takeEvery(LOGIN_AUTH, loginAuth);
    yield takeEvery(REMOVED_TOKENS_SUCCESS, clearAuthResponse);
}


