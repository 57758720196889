import React, { useEffect, useState, Suspense } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Redirect, Route, Switch } from "react-router-dom";
import * as actions from "../components/authentication/AuthenticationAction";
import routes from "./RouteConfig";
import AuthenticationComponent from "../components/authentication/AuthenticationComponent";
import isTokenValid from "../components/authentication/AuthenticationToken";
import NavigationBar from "../components/navbar/navbar";

const FallbackComponent = <div></div>;

const RouteComponent = (props) => {
  const [userData, setUserData] = useState({});
  const [sessionValid, setSessionValid] = useState(false);
  
  localStorage.setItem("envDetail", process.env?.REACT_APP_ENV);

  useEffect(() => {
    const userData = localStorage.getItem("tokenData");
    const userDetailsJson = JSON.parse(userData);
    if (props.exp) {
      setUserData(userData);
      isTokenValid(props.exp);
    }
  });

  return (
    //  TODO : need to add spinner
    <Suspense fallback={FallbackComponent}>

      {props.accessToken ?
        (
          <>
            <NavigationBar> </NavigationBar>
            <Switch>
              {routes.map((route) => (
                <Route
                  path={route.path}
                  component={route.component}
                  exact={route.exact}
                  key={route.id}
                  history={props.history}
                />
              ))}
            </Switch>
          </>
        )
        : (
          <AuthenticationComponent userDetails={userData} /> // I am logged in 
        )}

    </Suspense>
  );
};

const mapStateToProps = (state) => ({
  decodedToken: state.AuthenticationReducer.decodedToken,
  accessToken: state.AuthenticationReducer.accessToken,
  exp: state.AuthenticationReducer.decodedToken.exp,
});

const mapDispatchToProps = (dispatch) => ({
  action: bindActionCreators({ ...actions }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(RouteComponent);
