import  {
        PERFORM_LOGIN,
        PERFORM_LOGOUT,
        LOGIN_AUTH,
        AUTH_SUCCESS,
        LOGOUT_LOADER,
        REMOVED_TOKENS_SUCCESS,
        SET_AUTH_TOKEN,
        INITIAL_DATA_DOWNLOAD,
        INITIAL_DATA_ERROR, 
        LOGIN_AUTH_ERROR,
        SET_AUTH_TOKEN_ERROR,
        INITIAL_DATA_DOWNLOAD_SUCCESS,
        SET_STATION
    } from './AuthenticationConstants';

export const authenticateAction= (code, tokenData, token) => ({
    type: PERFORM_LOGIN,
    code,
    tokenData, 
    token
});

export const authenticateSuccess= (responseData) => ({
    type: AUTH_SUCCESS,
    payload: responseData
});

export const setStation = (responseData) => ({
    type: SET_STATION,
    payload: responseData
});

export const listenLogout = (userId, stationCode, companyCode) => ({
    type: PERFORM_LOGOUT,
    userId,
    stationCode,
    companyCode
});

export const logoutSuccess= () => ({
    type: REMOVED_TOKENS_SUCCESS
});

export const logoutLoader = () => ({
    type: LOGOUT_LOADER,
});

export const setAuthToken= (accessToken, userId, station, company) => ({
    type: SET_AUTH_TOKEN,
    accessToken,
    userId,
    station,
    company
});

export const initialDataDownload= (userId, phoneNumber, stationCode, policyType="", policyAccepted="", deviceType, deviceId="", companyCode, workArea='', fcmToken='', adminUserId='') => ({
    type: INITIAL_DATA_DOWNLOAD,
    userId,
    phoneNumber,
    stationCode,
    policyType,
    policyAccepted,
    deviceType,
    deviceId,
    companyCode,
    workArea,
    fcmToken,
    adminUserId
});

export const initialDataDownloadSuccess = (resData) => ({
    type: INITIAL_DATA_DOWNLOAD_SUCCESS,
    payload: resData
})
  
export const listenLogin=()=>({
    type:LOGIN_AUTH
})

export const initialDataError=()=>({
    type:INITIAL_DATA_ERROR
})
    
export const loginAuthError=()=>({
    type:LOGIN_AUTH_ERROR
})

export const setAuthTokenError=()=>({
    type:SET_AUTH_TOKEN_ERROR
})

