
import React, { useContext, useEffect, useState } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Redirect } from "react-router-dom";
import * as actions from "../authentication/AuthenticationAction";
import "./authentication.scss";
const Loading = ()=>{
  return (
    <div className="loading">Loading...</div>
  )
}


const AuthenticationComponent = (props)=>{

  const [redirect,setRedirect] = useState(null);
  useEffect(()=>{
    const { action } = props;
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const code = urlParams.get("code");
    const tokenData = localStorage.getItem("tokenData");
    const token = localStorage.getItem("token");
    const loggedout = localStorage.getItem("loggedout");

    if(loggedout === 'true'){
       localStorage.setItem('loggedout','');
       localStorage.clear();
       return
    }
    if ((!code && !token)) {
      action.listenLogin();
      return;
    }
    if (code && !token) {
      action.authenticateAction(code, tokenData, token);
      return;
    }

    if (token) {
      const tokenData = JSON.parse(localStorage.getItem("tokenData"));
      console.log(tokenData);
      if(tokenData.amrappmytime === "MyTime_Super_Admin"){
        localStorage.setItem('adminType', 'super')
      }
      else if(tokenData.amrappmytime === "Mytime_Mando_Admin"){
        localStorage.setItem('adminType', 'admin')
      }
      else if(tokenData.amrappmytime === "Mando_Admin"){
        localStorage.setItem('adminType', 'mando')
      }
      else if(tokenData.amrappmytime === "MyTime_Admin"){
        localStorage.setItem('adminType', 'mytime')
      }
      else if(tokenData.amrappmytime === "Mando_Admin_Read"){
        localStorage.setItem('adminType', 'readOT')
      }
      else if(tokenData.amrappmytime === "Mytime_Admin_Read"){
        localStorage.setItem('adminType', 'readMT')
      }
      else if(tokenData.amrappmytime === "Mytime_Mando_Admin_Read"){
        localStorage.setItem('adminType', 'readMTOT')
      }
      else {
        localStorage.setItem('adminType', 'normal')
      }
      window.addEventListener("storage", (e) => {
        const validToken = localStorage.getItem("token");
      });
      var accessToken = token;
     if(tokenData){
      action.authenticateSuccess({...tokenData,accessToken:token});
      }  
    }
  })
  if (redirect) {
    return <Redirect to={redirect} />;
  }
  return <Loading/>
}

const mapDispatchToState = (state) => ({
  decodedToken: state.AuthenticationReducer.decodedToken,
  accessToken: state.AuthenticationReducer.accessToken,
});

const mapDispatchToProps = (dispatch) => ({
  action: bindActionCreators({ ...actions }, dispatch),
});

export default connect(
  mapDispatchToState,
  mapDispatchToProps
)(AuthenticationComponent);