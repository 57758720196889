import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './release.scss';
import { Grid, Typography } from '@material-ui/core';
import CustomDialogModel from '../../elements/customDialog';
import Airlines from '../../assets/images/airlines.png'
import AALogo from '../../assets/images/aalogo.png'
import { Close } from '@material-ui/icons';
import { connect } from 'react-redux';
import useMediaQuery from '@mui/material/useMediaQuery';
import { downloadHelpDocument, getDocumentList } from '../../constants/constants';
import instance_api from '../../config/axiosConfig';

const ReleaseNotes = (props) => {
  const { open, handelClose, title, description, releaseNotes } = props
  const isMediaLarge = useMediaQuery('(min-width:600px)');
  const [documentListItems, setDocumentList] = useState([])
  const adminType = localStorage.getItem('adminType')

  const Header = ({ array }) => {
    let counter = 0;
    const headers = ['Item Description', 'Help Document']
    return headers.map((x) => {
      ++counter;
      return (
        <th className='thStyle' style={{
          color: 'white', fontWeight: 'bold', fontSize: '18px',
          padding: '0 0 0 20px',
          textAlign: 'center', width:counter == 3 ? '40%' : '40%'
        }} key={counter}>
          {x}
        </th>
      );
    });
  };

  const getAdminType = (admin) => {
    if (admin == 'MyTime_Admin') {
      return "admin"
    } else if (admin == 'MyTime_Super_Admin') {
      return "super"
    } if (admin == 'admin') {
      return "MyTime_Admin"
    } else if (admin == 'super') {
      return "MyTime_Super_Admin"
    } else if (admin == 'normal') {
      return "ALL"
    } else {
      return "normal"
    }
  }

  useEffect(async () => {
    getDocuments()
  }, []);

  const getDocuments = async () => {
    const response = await instance_api('get', getDocumentList());
    if (response?.status === 200) {
      var documentList = []
      response.data.map((item) => {
        var document = item
        document.accessLevel = getAdminType(item.accessLevel)
        documentList.push(document)
      })
      var includeDocuments = []
      if (adminType == 'super') {
        documentList.map((item) => {
          var document = {
            "value": item.documentId,
            "label": item.displayText
          }
          includeDocuments.push(document)
        })
        setDocumentList(documentList);
      } else if (adminType == 'admin') {
        documentList.filter(listItem => (listItem.accessLevel == 'admin' || listItem.accessLevel == "normal")).map((item) => {
          var document = {
            "value": item.documentId,
            "label": item.displayText
          }
          includeDocuments.push(document)
        })
        setDocumentList(documentList.filter(listItem => (listItem.accessLevel == 'admin' || listItem.accessLevel == "normal")));
      } else if (adminType == 'normal') {
        documentList.filter(listItem => (listItem.accessLevel == "normal")).map((item) => {
          var document = {
            "value": item.documentId,
            "label": item.displayText
          }
          includeDocuments.push(document)
        })
        setDocumentList(documentList.filter(listItem => (listItem.accessLevel == 'normal')));
      }
    }
  }

  const downloadDocument = async (fileName) => {
    const documentName = documentListItems.filter(item => item.documentId == fileName)
    if(documentName != null && documentName.length > 0){
      const response = await instance_api('get', downloadHelpDocument(documentName[0].fileName), '', '', 'blob');
      const file = new Blob(
        [response.data],
        { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);
      //Open the URL on new Window
      window.open(fileURL);
    }
  }

  const releaseItem = props.activeRelease.releaseItems && props.activeRelease.releaseItems.length > 0
       && props.activeRelease.releaseItems.map((item) => {
    return (
      <div>
        <li style={{ padding: '10px 10px 10px 20px' }}>{item.itemDescription}</li>
        {item.helpDocument != null &&
          <a href="#" onClick={() => downloadDocument(item.helpDocument)}style={{ margin: '10px 10px 10px 40px', textAlign: 'center' }}>{'Click here for More Information'}</a>}
      </div>
      // <tr key={item.itemNo}>
      //   <td>
      //     <span style={{ width: '40%' }}>
      //       <span style={{ textAlign:'center', display: 'block' }}>{item.itemDescription}</span>
      //     </span>
      //   </td>
      //   <td onClick={() => downloadDocument(item?.itemDescription)}>
      //     <span style={{ width: '40%' }}>
      //       <span style={{ textAlign:'center',display: 'block'   }}>{item.helpDocument}</span>
      //     </span>
      //   </td>
      // </tr>
    )
  })

  return (
    <div className='requestSuccessModel report'>
      <CustomDialogModel
        onClose={handelClose}
        open={open}
        styles={{ width: '100%' }}
        className='requestSuccessModel'
      >
        <div style={{ backgroundColor: 'red', alignItems: 'flex-end' }}>
          <Close style={{
            alignSelf: 'end',
            position: 'absolute',
            right: '10px',
            marginTop: '-30px'
          }} onClick={() => handelClose()} />
        </div>
        <div style={{ flexDirection: 'column', columnCount:'2', }}>
          {
            isMediaLarge &&
            <div style={{ display: 'grid' }}>
              <Grid
                container
                spacing={2}
                direction="row"
              >
                <Grid item md={12} xs={12}>
                  <img
                    src={AALogo}
                    alt="logo"
                    style={{ "height": "100px", "width": "150px", }} />
                  <div style={{ display: 'grid', justifyContent: 'center', paddingTop: '10px' }}>
                    <Typography variant="h4" className='title' style={{ fontSize: '20px',  }}>{props.activeRelease.title?.toUpperCase()}</Typography>
                  </div>
                </Grid>
              </Grid>
            </div>
          }
          <div  style={{}}>
            <Grid item md={12} xs={12}>
              {
                !isMediaLarge &&
                <div>
                  <img
                    src={AALogo}
                    alt="logo"
                    style={{ "height": "50px", "width": "100px", }} />
                </div>
              }  
              <img
                src={Airlines}
                alt="airlines"
                style={{ "height": "100%", "width": '100%', }} />
            </Grid>
          </div>
        </div>
        <Grid item md={12} xs={12} style={{ marginTop: '20px' }}>
          {!isMediaLarge &&
            <Typography variant="h4" className='title' style={{ fontSize: '16px', justifyContent: 'center' }}>{props.activeRelease.title}</Typography>
          }
          <Typography style={{ fontSize: '14px' }}>
            {props.activeRelease.description}
          </Typography>
        </Grid>
        <Grid item md={12} xs={12} style={{ marginTop: '20px' }}>
          <Typography style={{ fontSize: '18px' }}>
            Release Items
          </Typography>
        </Grid>
        
        {
            <table className='email-new-continer-table'
              style={{
                padding: '20px 0px 0px 40px',
                width: '100%',
              }}>
                <tbody>
                  {releaseItem}
                </tbody>
              </table>
        }
      </CustomDialogModel>

    </div>
  );
};
ReleaseNotes.propTypes = {
  open: PropTypes.bool.isRequired,
  handelClose: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  activeRelease: state.HomePageReducer.releaseInfo
});

export default connect(mapStateToProps)(ReleaseNotes);
