import {
    createStore, applyMiddleware, compose,
  } from 'redux';
  import createSagaMiddleware from 'redux-saga';
  import rootReducer from './Reducer/reducer';
  import {persistStore } from 'redux-persist';
  import {  watchAuthentication } from './Sagas/rootSaga';


  function saveToLocalStorage(state) {
    try {
      const serializedState = JSON.stringify(state)
      localStorage.setItem('state', serializedState)
    } catch (err) {
      console.log(err)
    }
  }
  
  function loadFromLocalStorage() {
    try {
      const serializedState = localStorage.getItem('state');
      if (serializedState === null) return undefined;
      return JSON.parse(serializedState)
    } catch (err) {
      console.log(err)
      return undefined;
    }
  }
  
  const persistedState = loadFromLocalStorage();
  const sagaMiddleware = createSagaMiddleware();
  // const persistConfig = {
  //   key:'root', storage
  // }
  // const persistedReducer = persistReducer(persistConfig, rootReducer);
  const composeEnhancers = process.env.NODE_ENV === 'production' ? compose : (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose);
  // eslint-disable-next-line import/no-anonymous-default-export
  const configureStore = () => {
    const store = createStore(rootReducer,persistedState, composeEnhancers(applyMiddleware(sagaMiddleware)));
    sagaMiddleware.run(watchAuthentication);
    store.subscribe(() => saveToLocalStorage(store.getState()))
    return {store};
  
  }
  export default configureStore;
  