import  {PERFORM_LOGIN, REMOVED_TOKENS_SUCCESS, AUTH_SUCCESS, LOGOUT_LOADER, INITIAL_DATA_DOWNLOAD_SUCCESS, SET_STATION } from './AuthenticationConstants';
import {trimUserId} from './AuthenticationHandler';

const initialState={
    accessToken : undefined,
    idToken: undefined,
    validUntil: undefined,
    firstName: "",
    lastName:"",
    userId:"",
    role:"",
    email:"",
    station:"",
    decodedToken:{},
    isAdmin: false,
    logoutLoader: false,
    registerData: {}
};

export const AuthenticationReducer =(state=initialState, action) =>{
    switch(action.type) {
        case PERFORM_LOGIN: {
            return {
                ...state
            }
        }
       
        case AUTH_SUCCESS: {
            window.dynatraceUserId = action.payload.uid;
            return (
                {
                    ...state,
                    accessToken : action.payload.accessToken,
                    idToken: action.payload.accessToken,
                    validUntil: action.payload.exp,
                    firstName: action.payload.firstname,
                    lastName: action.payload.lastname,
                    station: action.payload.station,
                    email: action.payload.email,
                    userId: action.payload.uid,
                    role: action.payload.SM_ROLE,
                    decodedToken: {
                        ...action.payload,
                        amrcompany: action.payload.amrcompany === "" ? "AA" : action.payload.amrcompany
                    },
                    isAdmin: (action.payload?.amrappmytime === 'MyTime_Super_Admin' || action.payload?.amrappmytime === 'MyTime_Admin') ? true : false,
                    logoutLoader: false
                }
            )

        }
        case SET_STATION: {
            return {
                ...state,
                previousStation: action.payload
            }
        }
        case LOGOUT_LOADER: {
            return {
                ...state,
                logoutLoader: true
            }
        }
        case REMOVED_TOKENS_SUCCESS : {
                return initialState;
        }
        case INITIAL_DATA_DOWNLOAD_SUCCESS: {
            return {
                ...state,
                registerData: action.payload
            }
        }
        default: {
            return state;
        }
    } 

}

export default AuthenticationReducer;
