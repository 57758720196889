import axios from "axios";
import {base_url} from '../constants/constants';
import * as auth_actions from '../components/authentication/AuthenticationAction';

// Set config defaults when creating the instance
// const instance = axios.create({
//   baseURL: base_url,
// });

const token = localStorage.getItem("token");

//const token = 'eyJhbGciOiJSUzI1NiIsImtpZCI6Imxvc3R0aW1lIiwicGkuYXRtIjoidDFxZSJ9.eyJzY29wZSI6Im9wZW5pZCBwcm9maWxlIiwiY2xpZW50X2lkIjoibG9zdHRpbWUtc3RhZ2UiLCJpc3MiOiJodHRwczovL2lkcHN0YWdlLmFhLmNvbSIsInVpZCI6IjAwNzA1ODQ4IiwiZmlyc3RuYW1lIjoiTmloYXIgSnlvdGkiLCJhbXJhcHBteXRpbWUiOiJNeVRpbWVfU3VwZXJfQWRtaW4iLCJsYXN0bmFtZSI6IlBhcmlkYSIsImV4cCI6MTYzODk2MTI3Mn0.SXoTNBO3Mvvw7SA0ZkZwjJMj4R5Ruh5Bh3rFBhECSeAqMSW6i1ddbB3lsY_jwWap9ZiZizj_YEXwPN_Q6UO31_cLH6iXlRiEWhLyNCdcmekfzBOYcCKkU3YwQn_E8KTddu4vd8M9ogC58qkP42bxJc_AE8mDZcoH0H1izXgchV0ieOoBr8N9RplzW7tYE-TD-dlrz-mAOUJDTxZ1ISQqD4_PnWi-nr_no185viso29n83h6-U6h1lBi4wMq98OkpMA4C0GVphRFR2I0bdPG9VZNUVOb_SHn13jurAD9dvc1JkGPR1mA13Vo3ZVfsoZaTkB6U8TjYQekz5GcvTDLizQ';
// instance.defaults.headers.common["Authorization"] = 'Bearer ' + token;
//instance.defaults.headers.post['Content-Type'] = 'application/json';
//instance.defaults.timeout = 30000;

// instance.interceptors.response.use(response => {
//   return response;
// }, error => {
//   return Promise.reject(error);
// });

// export default instance;

axios.defaults.headers.common["Authorization"] = 'Bearer ' + token;
const instance_api = (type, url, data, headers, responseType) => {
  const options = {
    method: type,
    url: url,
   // responseType: JSON,
   responseType: responseType || JSON ,
    headers:
    {
      fileFormat: headers
    }
    // headers: {
    //   'Content-Type': 'application/json',
    //   'Access-Control-Allow-Origin': true,
    //   'withCredentials': false,
    //   'Access-Control-Allow-Credentials': true,
    // },
  }
  if (data) {
    options.data = data;
  }

  return axios(options)
    .then((response) => {
      return response;
    })
    .catch((error) => {
       if(error.toString().includes('401')){
         auth_actions.logoutSuccess();
         return {code:401}
       }
       else{
         
        return error;
       }
    })
};

export default instance_api;

