import { put, call, delay } from 'redux-saga/effects';
import * as actions from '../../components/authentication/AuthenticationAction';
import qs from 'qs'
import axios from 'axios';
import jwt from 'jwt-decode';
// import resources from  '../../utils/resources.json';
import resource from "../../utils/resource.json";

const getAccessToken = (options) => {
  return axios(options)
}

// const resources = resource[localStorage.getItem("envDetail")];

export function* fetchAccessToken(params) {
  const resources = resource[localStorage.getItem("envDetail")];

  if (params.tokenData === null) {
    try {
      const config = {
        method: 'post',
        url: resources.OauthUrl,
        data: qs.stringify({
          grant_type: 'authorization_code',
          code: params.code,
          client_id: resources.client_id,
          redirect_uri: resources.redirectUri
        }),
        headers: {
          'content-type': 'application/x-www-form-urlencoded;charset=utf-8'
        }
      }

      let users = yield call(getAccessToken, config);
      if (users.status !== 200) {
        clearAuthResponse();
      } else {
        const token = users.data.access_token;
        const IdToken = users.data.id_token;
        localStorage.setItem('token', users.data.access_token);
        localStorage.setItem('defaultToken', users.data.access_token);
        localStorage.setItem('IdToken', users.data.id_token);
        const defaultToken = localStorage.getItem('defaultToken', users.data.access_token);
        const user = jwt(token);
        console.log('user', user);
        // user.amrappmytime = 'MyTime_Admin';
        localStorage.setItem('tokenData', JSON.stringify(user));
        localStorage.setItem('logout', '');
        yield window.location.href = resources.redirectUri;
      }
    } catch (error) {
      clearAuthResponse();
    }
  } else {
    yield put(
    );
  }
}

export function* loginAuth() {
  const resources = resource[localStorage.getItem("envDetail")];

  try {
    window.location.href = resources.loginUrl;
    yield null;
  }
  catch (error) {
    yield put(actions.loginAuthError());
  }
}


export function* clearAuthResponse(action) {
  const resources = resource[localStorage.getItem("envDetail")];
  const idToken = localStorage.getItem('IdToken');  
  try {
    localStorage.clear();
    yield window.location.href = resources.logoutUrl.replace('${IDTOKEN}', idToken); 
  } catch (error) {
    localStorage.clear();
    yield window.location.href = resources.logoutUrl.replace('${IDTOKEN}', idToken)
  }
  localStorage.setItem("loggedout", true)
}

